import React from "react"
import { Link, graphql } from "gatsby"

import List from '../components/list'
import Layout from '../components/layout'
import Footer from '../components/footer'
import Card from '../components/card'
import Topo from '../styles/topo'

import { SectionHeading, Resources, Content, Topics, Wrap } from '../styles'

export default ({ data: { posts, topics } }) => (
  <Layout>
    <Topo>
      <Content>
        <Wrap>
          <h1>Welcome</h1>
          <p>Hello! This is a resource helping solo entrepreneurs, freelancers and contractors take care of their mental health. This site links to articles, videos and apps that help you build healthy habits, and tackle negative thoughts and patterns, so that you can have a happier, more productive and meaningful life.</p>
          <p>Check out our topic listing below, jump straight to the <Link to={"/resources"}>full linked resource list</Link>, or follow us on <a href="https://twitter.com/healthsolo">Twitter</a> to be notified of new resources.</p>
        </Wrap>
        <Topics>
            {topics.edges.map(t => 
              <Card key={t.node.frontmatter.tag.toLowerCase()} url={`/topic/${t.node.frontmatter.tag.toLowerCase()}`} title={t.node.frontmatter.title} body={t.node.frontmatter.subtitle} />
            )}
        </Topics>
      </Content>
    </Topo>
    <Resources>
      <Content>
        <SectionHeading>Recently Added Resources</SectionHeading>
          <List list={posts.edges.map(e => Object.assign({}, e.node.data, {tags: e.node.tags}))} />
      </Content>
    </Resources>
    <Footer />
  </Layout>
)

export const query = graphql`
  query {
    posts: allPrismicLink(limit: 3, sort: {fields: last_publication_date, order: DESC}) {
        edges {
          node {
            id
            tags
            data {
              title
              description
              type
              author
              publisher
              url
            }
          }
        }
      }
    topics: allMdx {
      edges {
        node {
          frontmatter {
            title
            tag
            subtitle
          }
        }
      }
    }
  }
`

