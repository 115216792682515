import styled from 'styled-components'

export const Wrap = styled.div`
    background-color: ${props => props.primary ? "#ffce4b" : "#fff"};
    flex-basis: 32%;
    margin-bottom: 25px;
    border: 1px solid #eee;
    border-color: ${props => props.primary ? "#ccc" : "#eee"};
    box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
    transition: .15s;
    min-height: 200px;
    overflow: hidden;
    border-radius: 4px;
    &:hover {
        transform: translateY(-4px);
        box-shadow: 0 16px 24px 0 rgba(60, 71, 81, .1)
    }
    a {
        display: block;
        padding: 30px;
        text-decoration: none;
        height: 100%;
    }

    @media (max-width: 768px) {
        flex-basis: 48%;
    }
    `

    export const Heading = styled.h3`
    color: #222;
    font-size: 20px;
    line-height: 26px;
    font-weight: 400;
    margin-bottom: 1em;
`

export const Text = styled.p`
    color: #222;
    margin-top: 1em;
    font-size: 14px;
    font-weight: 200;
`