import React from "react"
import { Link } from "gatsby"

import { Wrap, Heading, Text } from "../styles/card"

export default function Card({ title, url, body, primary = false }) {
  return (
    <Wrap primary={primary}>
      <Link to={url}>
        <Heading>{title}</Heading>
        <Text>{body}</Text>
      </Link>
    </Wrap>
  )
}
